@import "npm:resetize";

html,
body {
  min-height: 100%;
}

html {
  --color-red: hsl(0, 85.6%, 59%);
  --color-green: hsl(131, 52.8%, 46%);
  --color-blue: hsl(208, 76.9%, 47%);
  --color-yellow: hsl(29, 100%, 58%);
  --color-shade: rgba(0, 0, 0, 0.3);
  --color-light: rgba(255, 255, 255, 0.5);
  --color-background: hsl(208, 20%, 15%);
  --color-background-bright: hsl(208, 20%, 25%);
  --color-background-gray: hsl(208, 20%, 35%);
  --color-background-red: hsl(358, 45%, 37%);
  --color-background-green: hsl(136, 43%, 30%);
  --color-background-yellow: hsl(30, 53%, 37%);
  --color-text: hsl(0, 0%, 100%);
  --color-text-dimmed: hsl(208, 20%, 55%);

  --outset-light: inset 0.125rem 0.125rem rgba(255, 255, 255, 0.3);
  --outset-shade: 0.125rem 0.125rem rgba(0, 0, 0, 0.3);
  --inset: inset 0.125rem 0.125rem rgba(0, 0, 0, 0.3);

  background-color: var(--color-background);
  color: var(--color-text);
  color-scheme: dark;
  font-family: "Exo 2", sans-serif;
  overscroll-behavior: contain;
}

body {
  /* This is deprecated but still the only way to make scrollbars appear over the content. */
  overflow: overlay;
}

noscript {
  text-align: center;
  padding-inline: 1rem;
}

a,
input,
button {
  /* Make click instant on mobile. */
  touch-action: manipulation;
}

::-webkit-scrollbar {
  width: 1rem;
}

::-webkit-scrollbar-track {
  background-color: transparent;
}

::-webkit-scrollbar-thumb {
  background-clip: content-box;
  background-color: hsla(0, 0%, 100%, 0.3);
  border-radius: 0.5rem;
  border: 0.25rem solid transparent;
}

*:focus {
  outline: none;
}

#root {
  display: flex;
  flex-direction: column;
  justify-content: center;
  min-height: 100%;
  min-height: 100dvh;
  min-width: 22.5rem;
}
